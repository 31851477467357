import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_API_URL } from '../../../environments/environment';
import { ApiConstData } from '../../../consts/ApiConstData';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl = BASE_API_URL;
  headers:HttpHeaders;
  constructor(private httpClient: HttpClient,
              private  apiconstData: ApiConstData,
    ) {
      this.headers = new HttpHeaders().set("Content-Type",'application/json')
                                      .set('backend','1')
     }
  /**
   * 
   * @param username username of user that logged in to the system
   * @param password passwrd of user that logged in to the system
   */
  login(username:string,password:string,application_id){
    console.log("username => ", username);
    console.log("password=> ",password);

    let body = {
      "username" : username,
      "password" : password,
      "application_id": application_id,
      "lang_id": 2
    }
    return this.httpClient.post<any>(`${this.baseUrl}/login_with_user`,body,{headers:this.headers})
  }

  getApps(){
    return this.httpClient.post<any>(`${this.baseUrl}/applications`,{lang_id:2})
  }

}
